import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { imgPasswordVisible, imgPasswordInVisible, sidePic, logo, bigLogo } from "./assets";
import { ChangeEvent, KeyboardEvent } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';


type AreaCodeMap = {
  [state: string]: string[];
};

const areaCodeMap : AreaCodeMap = {
  "Alaska": ["907"],
  "Alabama": ["205", "251", "256", "334"],
  "Arkansas": ["479", "501", "870"],
  "Arizona": ["480", "520", "602", "623", "928"],
  "California": ["209", "213", "310", "323", "408", "415", "510", "530", "559", "562", "619", "626", "650", "661", "707", "714", "760", "805", "818", "831", "858", "909", "916", "925", "949", "951"],
  "Colorado": ["303", "719", "970"],
  "Connecticut": ["203", "860"],
  "District of Columbia": ["202"],
  "Delaware": ["302"],
  "Florida": ["239", "305", "321", "352", "386", "407", "561", "727", "772", "813", "850", "863", "904", "941", "954"],
  "Georgia": ["229", "404", "478", "706", "770", "912"],
  "Hawaii": ["808"],
  "Iowa": ["319", "515", "563", "641", "712"],
  "Idaho": ["208"],
  "Illinois": ["217", "309", "312", "618", "630", "708", "773", "815", "847"],
  "Indiana": ["219", "260", "317", "574", "765", "812"],
  "Kansas": ["316", "620", "785", "913"],
  "Kentucky": ["270", "502", "606", "859"],
  "Louisiana": ["225", "318", "337", "504", "985"],
  "Massachusetts": ["413", "508", "617", "781", "978"],
  "Maryland": ["301", "410"],
  "Maine": ["207"],
  "Michigan": ["231", "248", "269", "313", "517", "586", "616", "734", "810", "906", "989"],
  "Minnesota": ["218", "320", "507", "612", "651", "763", "952"],
  "Missouri": ["314", "417", "573", "636", "660", "816"],
  "Mississippi": ["228", "601", "662"],
  "Montana": ["406"],
  "North Carolina": ["252", "336", "704", "828", "910", "919"],
  "North Dakota": ["701"],
  "Nebraska": ["308", "402"],
  "New Hampshire": ["603"],
  "New Jersey": ["201", "609", "732", "856", "908", "973"],
  "New Mexico": ["505", "575"],
  "Nevada": ["702", "775"],
  "New York": ["212", "315", "516", "518", "585", "607", "631", "716", "718", "845", "914"],
  "Ohio": ["216", "330", "419", "440", "513", "614", "740", "937"],
  "Oklahoma": ["405", "580", "918"],
  "Oregon": ["503", "541"],
  "Pennsylvania": ["215", "412", "570", "610", "717", "724", "814"],
  "Rhode Island": ["401"],
  "South Carolina": ["803", "843", "864"],
  "South Dakota": ["605"],
  "Tennessee": ["423", "615", "731", "865", "901", "931"],
  "Texas": ["210", "214", "254", "281", "325", "361", "409", "432", "512", "713", "806", "817", "830", "903", "915", "936", "940", "956", "972", "979"],
  "Utah": ["435", "801"],
  "Virginia": ["276", "434", "540", "703", "757", "804"],
  "Vermont": ["802"],
  "Washington": ["206", "253", "360", "425", "509"],
  "Wisconsin": ["262", "414", "608", "715", "920"],
  "West Virginia": ["304"],
  "Wyoming": ["307"]
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  btnContinueBlockStatus: boolean;
  sidePic: string;
  logo: string;
  bigLogo: string;
  phone: string;
  phoneIsRegisterd: boolean;
  confirmPassword: string;
  password:string;
  showPassword: boolean;
  showConfirmPassword: boolean;
  totalSeconds: number,
  mobile_otp: string[],
  errorNoRegisteredUser: boolean,
  inputErrors: {
    phoneError: boolean,
    passwordError: boolean,
    confirmPasswordError: boolean,
    passwordEmpty:boolean,
    confirmPasswordEmpty:boolean,
  },
  phoneErrorMessage:string,
  otpErrorMessage:string,
  resendOtpSuccessMessage:string,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword: boolean = false;
  requestUpdateNewPasswordCallId: any;
  requestMobileOtpVerifyCallId: any;
  requestCheckAuthUserByPhoneCallId: any;
  resendPhoneOtpAPIId:any;
  intervalId:any;
  

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  imgPasswordInVisible: any = imgPasswordInVisible;
  imgPasswordVisible: any = imgPasswordVisible;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired),
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired),
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number().min(4).required(configJSON.otpCodeIsRequired),
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(2, configJSON.passwordMustBeAtLeast2Characters),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            configJSON.passwordsMustMatch
          ),
        }),
    };

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      logo: logo.default,
      bigLogo: bigLogo.default,
      sidePic: sidePic.default,
      btnContinueBlockStatus: true,
      phone:"",
      phoneIsRegisterd:true,
      password:"",
      confirmPassword:"",
      showPassword: false,
      showConfirmPassword: false,
      mobile_otp: ['','','',''],
      errorNoRegisteredUser: false,
      totalSeconds:120,
      inputErrors: {
        phoneError: false,
        passwordError: false,
        confirmPasswordError: false,
        passwordEmpty:false,
        confirmPasswordEmpty:false,
      },
      phoneErrorMessage:"",
      otpErrorMessage:"",
      resendOtpSuccessMessage:""
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    if(window.location.pathname==="/ForgotPasswordOTPWeb"){
      const phoneNumber = localStorage.getItem('phoneNumber');
      if(phoneNumber != undefined){
        this.handleValues("phone number", phoneNumber)
      }
    }
    this.startTimer();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      this.tokenValidation(message);
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.validationAPICallId &&
      this.validationAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.passwordValidation(message)
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.requestEmailOtpCallId !== null &&
      this.requestEmailOtpCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.emailOtpValidation(message)
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.requestPhoneOtpCallId !== null &&
      this.requestPhoneOtpCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.phoneValidation(message);
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.requestGoToConfirmationCallId !== null &&
      this.requestGoToConfirmationCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.accountConfirmation(message)
    } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      this.countryCodeSplit(message)
    }
    this.otpAndPhoneValidation(message)
    
    // Customizable Area End
  }

  // Customizable Area Start

  tokenValidation=async(message: Message)=>{
    const otpAuthTkn = message.getData(
      getName(MessageEnum.AuthTokenDataMessage)
    );

    if (otpAuthTkn && otpAuthTkn.length > 0) {
      this.setState({ token: otpAuthTkn });
      if (this.isChangePassword) {
        this.setState({ accountStatus: "ChangePassword" });
      }
      this.otpToken = this.state.token;
      // runEngine.debugLog("otpAuthTkn", this.state.token);
    } else {
      const accountType = message.getData(
        getName(MessageEnum.NavigationForgotPasswordPageInfo)
      );
      if (accountType) {
        this.startForgotPassword(accountType);
      }
    }
  }

  passwordValidation= async(message: Message)=>{
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson === undefined) {
      return;
    }

    if (responseJson && responseJson.data[0]) {
      const passRegex = RegExp(
        responseJson.data[0].password_validation_regexp
      );
      const emailRegex = RegExp(responseJson.data[0].email_validation_regexp);
      const passwordRulesFromValidation =
        responseJson.data[0].password_validation_rules;

      this.setState({
        //email schema
        emailSchema: {
          email: Yup.string()
            .email(configJSON.pleaseEnterAValidEmail)
            .required(configJSON.emailIsRequired)
            .matches(emailRegex, configJSON.invalidEmailAddress),
        },
        //password schema
        passwordSchema: {
          password: Yup.string()
            .required(configJSON.pleaseEnterAPassword)
            .matches(passRegex, configJSON.invalidPassword),

          confirmPassword: Yup.string()
            .required(configJSON.pleaseConfirmYourPassword)
            .when("password", {
              is: (val) => (val && val.length > 0 ? true : false),
              then: Yup.string().oneOf(
                [Yup.ref("password")],
                configJSON.passwordsMustMatch
              ),
            }),
        },
        passwordRules: passwordRulesFromValidation,
      });
    }
  }

  emailOtpValidation= async(message: Message)=>{
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      responseJson !== undefined &&
      responseJson.meta &&
      responseJson.meta.token
    ) {
      this.otpToken = responseJson.meta.token;

      this.setState({ token: this.otpToken });

      //navigate to OTP page
      const msg: Message = new Message(
        getName(MessageEnum.NavigationMobilePhoneOTPMessage)
      );

      msg.addData(
        getName(MessageEnum.AuthTokenDataMessage),
        this.state.token
      );

      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      msg.addData(
        getName(MessageEnum.AuthTokenEmailMessage),
        this.state.emailValue
      );

      msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);

      this.send(msg);
    }
    //error handling
    else if (responseJson && responseJson.errors) {
      this.parseApiErrorResponse(responseJson);
    } else {
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  phoneValidation= async(message: Message)=>{
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      responseJson !== undefined &&
      responseJson.meta &&
      responseJson.meta.token
    ) {
      this.otpToken = responseJson.meta.token;
      this.setState({ token: this.otpToken });

      const msg: Message = new Message(
        getName(MessageEnum.NavigationMobilePhoneOTPMessage)
      );
      msg.addData(
        getName(MessageEnum.AuthTokenDataMessage),
        this.state.token
      );

      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      msg.addData(
        getName(MessageEnum.AuthTokenPhoneNumberMessage),
        this.state.phoneValue
      );

      msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);

      this.send(msg);
    }
    //error handling
    else if (responseJson && responseJson.errors) {
      this.parseApiErrorResponse(responseJson);
    } else {
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  accountConfirmation= async(message: Message)=>{
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson !== undefined && responseJson.data) {
      this.setState({
        accountStatus: "Confirmation",
      });
    } else if (responseJson !== undefined && responseJson.errors) {
      this.parseApiErrorResponse(responseJson);
    } else {
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  countryCodeSplit= async(message: Message)=>{
    var selectedCode = message.getData(
      getName(MessageEnum.CountyCodeDataMessage)
    );

    if (selectedCode !== undefined) {
      this.setState({
        countryCodeSelected:
          selectedCode.indexOf("+") > 0
            ? selectedCode.split("+")[1]
            : selectedCode,
      });
    }
  }

  otpAndPhoneValidation= async(message: Message)=>{
    const isRestAPIResponse = getName(MessageEnum.RestAPIResponceMessage) === message.id;
  
    if (isRestAPIResponse) {
      const responseId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  
      if (this.requestCheckAuthUserByPhoneCallId === responseId) {
        await this.handleAuthUserResponse(message);
      } else if (this.requestChangePasswordCallId === responseId) {
        this.handleChangePasswordResponse(message);
      } else if (this.requestUpdateNewPasswordCallId === responseId) {
        this.handleUpdateNewPasswordResponse(message);
      }
      else if (this.resendPhoneOtpAPIId === responseId) {
        this.handleResendOtpResponse(message);
      }
    }
  }

  startForgotPassword(accountType: String) {
    this.setState({
      accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail",
    });
  }

  startTimer = () => {
    this.intervalId = setInterval(() => {
      this.setState((prevState) => ({
        totalSeconds: prevState.totalSeconds - 1,
      }), () => {
        if (this.state.totalSeconds <= 0) {
          this.stopTimer(); 
        }
      });
    }, 1000);
  };

  stopTimer = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null; 
    }
  };
  

  goToOtpAfterEmailValidation(values: { accountType: string; email: string }) {
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.requestEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      emailValue: values.email ? values.email : "",
    });

    const data = {
      type: values.accountType ? values.accountType : "email_account",
      attributes: {
        email: values.email ? values.email : "",
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToOtpAfterPhoneValidation(values: { phone: string }) {
    if (
      !this.state.countryCodeSelected ||
      this.state.countryCodeSelected.length === 0
    ) {
      this.showAlert(
        configJSON.goToOtpAfterPhoneValidationErrorTitle,
        configJSON.goToOtpAfterPhoneValidationErrorBody
      );
      return;
    }
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.requestPhoneOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      phoneValue:
        this.state.countryCodeSelected && values.phone
          ? this.state.countryCodeSelected + values.phone
          : "",
    });

    const data = {
      type: "sms_account",
      attributes: {
        full_phone_number: this.state.phoneValue,
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToChangePasswordAfterOtp(values: { otpCode: string }) {
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestChangePasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryConfirmOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      otp_code: values.otpCode ? values.otpCode : "",
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToConfirmationAfterPasswordChange(values: {
    password: any;
    confirmPassword: any;
  }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestGoToConfirmationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryChangePasswordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      new_password: values.password,
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End

  // Customizable Area Start
  validatePhoneNumber(value: string) {
    const isValidFormat = /^(?:\+1\s?)?(?:\(?([2-9]\d{2})\)?[\s.-]?)?([2-9]\d{2})[\s.-]?(\d{4})$/.test(value);
    
    const cleanNumber = value.replace(/\D/g, ''); 
    const areaCode = cleanNumber.substring(0, 3); 
    
    const isValidAreaCode = this.validateAreaCode(areaCode);

    const isValid = isValidFormat && isValidAreaCode;
    
    this.setState({
        inputErrors: { ...this.state.inputErrors, phoneError: !isValid },
        phone: value,
    });
    
}
  
  validatePassword(value: string) {
    const passwordEmpty = value === '';
    const isValid =
      !passwordEmpty && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/.test(value);

    this.setState({
      password: value,
      inputErrors: {
        ...this.state.inputErrors,
        passwordEmpty,
        passwordError: !isValid,
        confirmPasswordError: value !== this.state.confirmPassword
      },
      btnContinueBlockStatus: !(isValid && this.state.confirmPassword === value)
    });
  }


  validateConfirmPassword(value: string) {
    const confirmPasswordEmpty = value === '';
    const isValid = !confirmPasswordEmpty && this.state.password === value;
  
    this.setState({
      confirmPassword: value,
      inputErrors: {
        ...this.state.inputErrors,
        confirmPasswordEmpty,
        confirmPasswordError: !isValid,
      },
      btnContinueBlockStatus: !(this.state.password && isValid),
    });
  }
  
  
  handleValues = (name: string, value: string) => {
    switch (name) {
      case 'password':
        this.validatePassword(value);
        break;
      case 'confirm password':
        this.validateConfirmPassword(value);
        break;
      case 'phone number':
        this.validatePhoneNumber(value);
        break;
      default:
        break;
    }
  }
  

  handleInput = (e: any) => {
    let { name, value } = e.target;
    switch (name) {
      case 'password':
        this.handleValues(name, value);
        break;
      case 'confirm password':
        this.handleValues(name, value);
        break;
      case 'phone number':
        this.handleValues(name, value);
        break;
      default:
        break;
    }
  }


  convertSecondsToMinutes(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedRemainingSeconds = remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds.toString();
    return { minutes, remainingSeconds: formattedRemainingSeconds };
  }


  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  renderErrorMessage = () => {
    const { passwordEmpty, passwordError } = this.state.inputErrors;

    if (passwordEmpty) {
        return "Password field cannot be empty"
    }

    if (passwordError) {
        return "Please enter a valid password that meets the criteria"
    }

    return null;
  };

  renderConfirmErrorMessage = () => {
    const { confirmPasswordEmpty, confirmPasswordError } = this.state.inputErrors;

    if (confirmPasswordEmpty) {
        return "Confirm Password field cannot be empty"
    }

    if (confirmPasswordError) {
        return "Password and confirm password should be the same"
    }

    return null;
  };

  goTo = (name:string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      name
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  inputRefs: (HTMLInputElement | null)[] = [];

  handleChange = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    
    if (!/^\d?$/.test(value)) return;

    if (value.length > 1) return;

    const newOtp = [...this.state.mobile_otp];
    newOtp[index] = value;
    this.setState({ mobile_otp: newOtp });

    if (value && index < 3) {
      this.inputRefs[index + 1]?.focus();
    }
};


  handleKeyDown = (index: number) => (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && !this.state.mobile_otp[index] && index > 0) {
      this.inputRefs[index - 1]?.focus();
    }
  };

  componentDidUpdate(prevProps: {}, prevState: any) {
    if (prevState.mobile_otp !== this.state.mobile_otp) {
      const otpComplete = this.state.mobile_otp.every(digit => digit !== '');
      if (otpComplete) {
        this.setState({btnContinueBlockStatus:false})
      }else{
        this.setState({btnContinueBlockStatus:true})
      }
    }
  }

  

  async goToOtpVerifyAfterAuthCheck(values: { mobile_otp: string }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestCheckAuthUserByPhoneCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordCheckAuthUserEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      full_phone_number: values.mobile_otp ? `+1${values.mobile_otp.split(" ").join("")}` : "",
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    localStorage.setItem('phoneNumber',values.mobile_otp)
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  async goToChangePasswordAfterOtpWeb(values: { mobile_otp: string }) {
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestChangePasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordMobileOtpVerifyEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      mobile_otp: values.mobile_otp ? values.mobile_otp : "",
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async updateNewPassword(values: {
    password: any;
    confirmPassword: any;
  }) {
    const newErrors = { ...this.state.inputErrors };

    
    if (values.password === "") {
      newErrors.passwordEmpty = true;
    }
    
    if (values.confirmPassword === "") {
      newErrors.confirmPasswordEmpty = true;
    }
    
    if (values.password === "" && values.confirmPassword === "") {
      newErrors.passwordEmpty = true;
      newErrors.confirmPasswordEmpty = true;
    }

    this.setState({
      inputErrors: newErrors,
    });

    if(values.password && values.confirmPassword ){
      const token = localStorage.getItem('token')
  
      const header = {
        "Content-Type": configJSON.forgotPasswordAPiContentType,
        token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.requestUpdateNewPasswordCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.forgotPasswordUpdatePasswordEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      const data = {
        password: values.password,
        confirm_password: values.confirmPassword,
      };
  
      const httpBody = {...data};
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      localStorage.setItem('phoneNumber','')
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }


  async goToHomeWeb(){
    this.setState({phone:''})
    localStorage.setItem('phoneNumber','')
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  
  async handleAuthUserResponse(message: Message) {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
    if (responseJson?.token) {
      this.otpToken = responseJson.token.token;
      this.setState({ token: this.otpToken });
      localStorage.setItem('token', this.otpToken);
    
      this.props.navigation.navigate('ForgotPasswordOTPWeb')
    } else {
      this.handleErrorResponse(message, responseJson);
    }
  }
  
  handleChangePasswordResponse(message: Message) {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson.error) {
      this.setState({
        otpErrorMessage: "Incorrect code, please try again"
      })
    } if (responseJson.errors && responseJson.errors[0] ===  "OTP is expired, please request a new one") {
      this.setState({
        otpErrorMessage: "Code has expired, please request a new one"
      })
    }  else {
      this.props.navigation.navigate('NewPasswordWeb')
    }
  }
  
  handleResendOtpResponse(message: Message) {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson?.message === 'OTP send succesfully on your Phone number') {
      this.setState({
        resendOtpSuccessMessage:"The code was sent successfully"
      })
    }
    setTimeout(()=>{
      this.setState({
        resendOtpSuccessMessage:""
      })
    },3000)
  }
  
  handleErrorResponse(message: Message, responseJson: any) {
    if (responseJson.error || responseJson.errors) {
      this.setState({ errorNoRegisteredUser: true });
      this.handlePhoneNumberMessage();
    } else {
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleUpdateNewPasswordResponse(message: Message) {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
    if (responseJson?.message === 'Password updated successfully') {
      toast.success("Password updated successfully")
      setTimeout(()=>{
        this.props.navigation.navigate('LandingPage')
      },2000)
    } else {
      this.handleErrorResponse(message, responseJson);
    }
  }

  validateAreaCode(phoneNumber: string): boolean {
    const cleanNumber = phoneNumber.replace(/\D/g, '');
    
    const areaCode = cleanNumber.substring(0, 3);
    
    for (const state in areaCodeMap) {
        if (areaCodeMap[state].includes(areaCode)) {
            return true; 
        }
    }
    return false;
}

  handlePhoneNumberMessage=()=>{
    if(this.state.phone==""){
      this.setState({
        phoneErrorMessage:"Phone number can't be blank"
      })
    }
    else if(this.state.inputErrors.phoneError){
      this.setState({
        phoneErrorMessage:"Please enter a valid US phone number"
      })
    }
    else if(this.state.errorNoRegisteredUser){
      this.setState({
        phoneErrorMessage:"This phone number is not registered"
      })
    }
  }

  resendPhoneOtp=()=>{
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resendPhoneOtpAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordMobileOtpResendEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const httpBody = {
      data: {
        full_phone_number: `1${this.state.phone}`,
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({
      totalSeconds:120
    })
    this.startTimer();
  }
  
  // Customizable Area End
}
