// Customizable Area Start
import React from "react";

import {
  Typography,  
  Box,  
  Card,
  CardContent,
  CardMedia,
  Button,
  Container,
  Dialog
} from "@mui/material/";
import { styled } from '@mui/system';
import {img1, img2, img3, prevActivity , successIcon , warningIcon} from './assets'
import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController";
import Layout from "./Layout.web";
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import {removeStorageData } from "../../../framework/src/Utilities";
import SubscriptionReminderDialog from "./components/SubscriptionReminderDialog.web";


const GradientCircularProgress =()=>{
  return(
    <React.Fragment>
    <svg width={0} height={0}>
      <defs>
        <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="#ffbe01" />
          <stop offset="100%" stopColor="#ffbe01" />
        </linearGradient>
      </defs>
    </svg>
    <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} thickness={4.5} size={50} />
  </React.Fragment>
  )
}

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);

  }
  

  render(){
    const ActionCard = styled(Card)({
      height: '100%', 
      minHeight: '436px', 
      borderRadius: '14px', 
      backgroundColor: '#F1F5F9', 
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      overflow: 'hidden',
      justifyContent: 'flex-end', 
    });
    
    const ActionCardMedia = styled(CardMedia)({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1,
      backgroundSize: 'cover',
    });
    
    const ActionCardContent = styled(CardContent)({
      zIndex: 2,
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%)', 
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      padding: '16px',
      flexGrow: 1, 
      marginTop: 'auto', 
    });
    return (
      <Layout id="" navigation={this.props.navigation}>
        <Box sx={styles.mainBox} >
        
          <Box sx={styles.leftSide}>
            <Typography sx={styles.topHeading}>Dashboard</Typography>
            <Box sx={{ padding: '24px', width: '100%', 
              // minWidth: '886px'
              }}>
              <Box sx={{ padding: "30px 15px 15px 15px"}}>
                <Typography sx={styles.heading}>Welcome back {this.state.userFullName.split(" ")[0] || ""}</Typography>
                <Container sx={styles.cardMain}  style={{padding: 0, margin: 0, paddingTop: '26px'}}>
                  {[
                    {
                     title: 'Get support with your healthcare',
                     description: 'Contact our specialist advocates with your healthcare questions',
                     image: img1.default,
                     buttonText: 'Check your cases',
                     route: 'CustomForm'
                    },
                    {
                     title: 'Keep all healthcare documents together',
                     description: 'Upload photos of your health plan ID cards to access them whenever you need',
                     image: img2.default,
                     buttonText: 'View health wallet',
                     route: 'ContentManagement'
                    },
                    {
                     title: 'Get in touch for more support',
                     description: 'Contact us if you need more support with your healthcare',
                     image: img3.default,
                     buttonText: 'Contact Us',
                     route: 'Contactus'
                    },
                    ].map((card, index) => (
                      <Container sx={styles.card} style={{padding: 0, margin: 0}} key={index}>
                        <ActionCard>
                         <ActionCardMedia image={card.image} title={card.title} />
                         <ActionCardContent>
                          <Typography style={styles.cardHeading}>
                            {card.title}
                          </Typography>
                          <Typography style={styles.description}>
                            {card.description}
                          </Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              width: '100%', 
                              maxWidth: '223px', 
                              height: '50px', 
                              borderRadius: '7px',
                              padding: '14px',
                              gap: '7px',
                              background: 'linear-gradient(99.09deg, #FCD34D 2.64%, #D97706 100%)',
                              marginTop: '9px',
                              marginLeft: '9px',
                              '&:hover': {
                                bgcolor: 'primary.dark', 
                              },
                            }}
                            onClick={()=> this.navTo(card.route)}
                          >
                          <Typography sx={styles.btnText}>{card.buttonText}</Typography>
                          </Button>
                          </ActionCardContent>
                        </ActionCard>
                      </Container>
                    ))}
                 </Container>
              </Box>
            </Box>
          </Box>

          
          <Box sx={styles.rightSide}>
            <Box sx={styles.recentActivityBox}>
              <Typography sx={styles.recentActivityTitle}>
                Recent Activity
              </Typography>
            </Box>
            {this.state.recentActivities.length > 0 ? (
              <Box sx={styles.activityList}>
                {this.state.recentActivities.map((activity, index) => (
                  <Box key={index} sx={{ display: "flex" }}>
                    <Box sx={{ display: 'flex', 
                         paddingLeft: { xs: '10px', sm: '10px', md: '0px', lg: '10px' }, 
                         marginTop: {lg: '2px'},
                         marginBottom: {xs: '1px'} }}>
                      <img
                       src={prevActivity.default}
                       alt="icon"
                       style={{
                        width: '100%',
                      }}
                      />
                   </Box>
                   <Box>
                      <Typography sx={styles.listItemText}>
                         {activity.action} at <span style={styles.timeText}>{activity.dateTime}</span>
                      </Typography>
                   </Box> 
                 </Box>
                ))}
               </Box>) : (
                 <Box sx={{display: 'flex', alignItems:'center', justifyContent: 'center', height: '100%'}}>
                   <Typography sx={styles.noRecentActivityTxt}>No recent activity to display</Typography>
                 </Box>)}
          </Box>
        </Box> 
        <Dialog open={this.state.isShowProgressBar}>
        <Box sx={{backgroundColor:'#ffffff' , width:{sm:'500px', xs:'100%' } , height:'100%' , borderRadius:'20px' , padding:'10px'}}>
          <div data-test-id='closeShowProgress' onClick={()=>{  
             this.props.navigation.navigate('PaymentConfirmation')
             this.setState({isShowProgressBar : false})
          }} style={{display:'flex', justifyContent:'end' ,alignItems:'end'}} >
          <CloseIcon sx={{width:'20px'}} />
          </div>
         <Box sx={{display:'flex', justifyContent:'center', alignItems:'center' , padding:'60px'}} >
            <div>
            <div style={{display:'flex' , justifyContent:'center'}}> <GradientCircularProgress/></div>
            <p style={{fontFamily:'Inter' , fontSize:'14px' , fontWeight:700 , color:'#94A3B8'}}>Payment is being processed</p>
            </div>
           </Box>
         </Box>
        </Dialog>
        <Dialog open={this.state.isShowCongratulation}>
        <Box sx={{ backgroundColor: '#ffffff', width: { sm: '600px', xs: '100%' }, borderRadius: '20px', padding: '20px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }} >
              <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>   <img
                  src={successIcon.default}
                  alt="icon"
                  style={{
                    width: '64px',
                    height: '64px'
                  }}
                />
                </div>
                <Typography sx={{ fontFamily: 'Inter', fontSize: '24px', fontWeight: 700, color: '#0F172A', display: 'flex', justifyContent: 'center' }}>Congratulations!</Typography>
                <Typography variant="body1" align="center" gutterBottom sx={{ fontFamily: 'Inter', fontSize: '18px', fontWeight: 700, color: '#0F172A', paddingTop: '10px' }}>
                  You have successfully purchased Advantage PHC's Subscription Plan
                </Typography>
                <Typography align="center" sx={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: 400, color: '#64748B' }}>Now you have an access to all company’s services and clients support.</Typography>
                <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }} >
                  <Button 
                  data-test-id='closeShowCongratulation'
                    fullWidth 
                    onClick={()=>{removeStorageData('isShowProgress')
                      this.setState({'isShowCongratulation' : false})} }
                    variant="contained"
                    sx={{
                      background: 'linear-gradient(90deg, #f7b733, #dd8723)',
                      fontWeight: 600,
                      textTransform: 'none',
                      fontFamily: 'Inter',
                      width: '280px'
                    }}
                  >
                    Go to the Dashboard
                  </Button>
                </div>
              </div>
            </Box>
          </Box>
        </Dialog>
        <Dialog open={false}>
          <Box sx={{ backgroundColor: '#ffffff', width: { sm: '600px',xs: '200px' }, borderRadius: '20px', padding: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }} >
              <CloseIcon sx={{ width: '20px' }} />
            </div>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }} >
              <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>   <img
                  src={warningIcon.default}
                  alt="icon"
                  style={{
                    width: '56 px',
                    height: '56 px'
                  }}
                />
                </div>
                <Typography variant="body1" align="center" gutterBottom sx={{ fontFamily: 'Inter', fontSize: '18px', fontWeight: 700, color: '#0F172A', paddingTop: '10px' }}>
                Your payment was not processed successfully
                </Typography>
                <Typography align="center" sx={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: 400, color: '#64748B' }}>Please come back and edit your payment details</Typography>
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '50px' }} >
                  <Button
                    fullWidth
                    variant="contained"
                    data-test-id='createCaseBtn'
                    sx={{
                      background: 'linear-gradient(90deg, #f7b733, #dd8723)',
                      fontWeight: 600,
                      textTransform: 'none',
                      fontFamily: 'Inter',
                      width: '280px'
                    }}
                  >
                    Back to Payment information
                  </Button>
                </div>
              </div>
            </Box>
          </Box>
        </Dialog>
        <SubscriptionReminderDialog type={this.state.warningModalType} open={this.state.warningModalType !== "none"} onClose={this.handleReminderToggle} end_date={this.state.subscription?.end_date} />
      </Layout>
    );
  }
}

const styles = {
  topHeading: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: { xs: "18px", md: "24px" },
    lineHeight: "32px",
    fontWeight: 700,
    padding: '25px 0px 0px 13px'
  },
  heading: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: { xs: "28px", md: "36px" },
    lineHeight: "44px",
    fontWeight: 700,
  },
  cardHeading: {
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontSize: "22px",
    lineHeight: "29px",
    fontWeight: 800,
    padding: '0px 10px 0px 10px'
  },
  description: {
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontSize: "13px",
    lineHeight: "18px",
    fontWeight: 500,
    padding: '0px 10px 0px 10px'
  },
  btnText:{
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '14.4px',
    fontWeight: 700,
    lineHeight: '21.6px',
    textTransform: 'none',
  },
  mainBox: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    width: { xs: '100%', md: '100%', lg: '75%' }, 
  },
  cardMain:{
    display: 'flex',
    gap:'24px',
    flexWrap: 'wrap',
    padding: 0,
    margin: 0,
    overFlow: 'hidden',
    width: '100%',
    flexDirection: {xs: 'column', sm:'row', md: 'row', lg:'row'}
  },
  card:{
    width: {xs: '20rem', sm:'30rem', md: '22rem', lg:'22rem'},
    padding: 0,
    margin: 0,
  },
  rightSide: {
    borderLeft: "1px solid #E2E8F0",
    backgroundColor: "#F8FAFC", 
    width: { xs: '100%', sm: '100%', md: '100%', lg:'25%' }, 
    paddingBottom: '38px',
    // height: '100vh'
  },
  recentActivityBox: {
    width: "100%",
    height: "75px",
    backgroundColor: "#F1F5F9", 
    display: "flex",
    alignItems: "center",
    paddingLeft: "16px", 
  },
  recentActivityTitle: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
    color: "#475569", 
  },
  activityList: {
    marginTop: "16px", 
    paddingLeft: "16px", 
    display: 'flex',
    flexDirection: 'column',
    gap: '24px'
  },
  listItemText: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: '18px',
    color: "#475569", 
    marginLeft: '8px',
    padding: '0px 38px 0px 0px'
  },
  timeText: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: '18px',
    color: "#F59E0B",
  },
  noRecentActivityTxt: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: '24px',
    color: "#94A3B8",
  }
};

// Customizable Area End
